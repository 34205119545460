<template>
  <div class="topEmpresasBox" :class="{ last: index == 4 }">
    <div class="boxId sb-1 sb-right" :class="{ escuro: index % 2 !== 0 }">
      {{ index + 1 }}
    </div>
    <div class="box01">
      <div
        class="box40 titles sb-2 sb-right"
        :class="{ escuro: index % 2 !== 0 }"
        :id="'B3Ranking-0' + index"
      >
        CNPJ
      </div>
      <div class="box40 sb-2 results text-send">
        {{ formatarCNPJ(resultados[`bk_304_006_num_cnpj_${index + 1}`]) }}
        <span
          v-if="
            !telaHistorico &&
            !resultados[`bk_304_006_num_cnpj_${index + 1}`].includes('*') &&
            !gerandoPdf
          "
          class="material-symbols-outlined icon-send"
          @click="
            selecionaConsultaAdicional(
              resultados[`bk_304_006_num_cnpj_${index + 1}`]
            )
          "
        >
          send
        </span>
      </div>
      <div
        class="box40 titles sb-2 sb-right"
        :class="{ escuro: index % 2 !== 0 }"
        :id="'B3Ranking-1' + index"
      >
        CNAE
      </div>
      <div class="box40 sb-2 results" :id="'num_cnae' + (index + 1)">
        {{ resultados[`bk_304_006_num_cnae_${index + 1}`] }}
      </div>
      <b-tooltip
        :target="'num_cnae' + (index + 1)"
        triggers="hover"
        placement="bottom"
        variant="dark"
        boundary="window"
        customClass="tooltip-custom"
      >
        {{ resultados[`bk_304_006_des_cnae_${index + 1}`] }}
      </b-tooltip>
    </div>
    <div class="box02">
      <div
        class="box80 sb-1 sb-right titles"
        :class="{ escuro: index % 2 !== 0 }"
        :id="'B3Ranking-2' + index"
      >
        Razão Social
      </div>
      <div
        class="box80 sb-1 sb-right titles"
        :class="{ escuro: index % 2 !== 0 }"
        :id="'B3Ranking-3' + index"
      >
        Nome Fantasia
      </div>
    </div>
    <div class="box02">
      <div class="box80 sb-3 sb-right results">
        {{ resultados[`bk_304_006_des_razao_social_${index + 1}`] }}
      </div>
      <div class="box80 sb-3 sb-right results">
        {{ resultados[`bk_304_006_des_nome_fantasia_${index + 1}`] }}
      </div>
    </div>
    <div class="box01">
      <div
        class="box40 sb-2 sb-right titles"
        :class="{ escuro: index % 2 !== 0 }"
        :id="'B3Ranking-4' + index"
      >
        Status
      </div>
      <span class="tabTextBox sb-2 sb-right">
        <p
          v-for="(statusOption, indexStatus) in statusOptions"
          :key="indexStatus"
          :id="'B3Ranking-4' + '-' + statusOption + index"
          class="tabText"
          :class="{
            selecionado: compararValores(
              resultados[`bk_304_006_des_status_cadastral_${index + 1}`],
              statusOption
            ),
            'nao-selecionado': !compararValores(
              resultados[`bk_304_006_des_status_cadastral_${index + 1}`],
              statusOption
            ),
          }"
        >
          {{ statusOption }}
        </p>
      </span>
    </div>
    <div class="box4">
      <div class="box3">
        <div>
          <div
            class="box40 sb-1 sb-right titles text-center"
            :class="{ escuro: index % 2 !== 0 }"
            :id="'B3Ranking-5' + index"
          >
            Tipo
          </div>
          <div
            class="box40 sb-1 sb-right results text-center"
            :id="'Empresa-tipo-' + index"
            :class="{ escuro: index % 2 !== 0 }"
          >
            {{ naturezaJuridicaTratada(index) }}
          </div>
          <b-tooltip
            v-if="
              resultados[
                `bk_304_006_des_tipo_natureza_juridica_${index + 1}`
              ] != '-'
            "
            :target="'Empresa-tipo-' + index"
            triggers="hover"
            placement="bottom"
            variant="dark"
            class="tooltipTexts"
            boundary="window"
          >
            {{
              resultados[`bk_304_006_des_tipo_natureza_juridica_${index + 1}`]
            }}
          </b-tooltip>
        </div>
        <div>
          <div
            class="box40 sb-1 sb-right titles text-center"
            :class="{ escuro: index % 2 !== 0 }"
            :id="'B3Ranking-6' + index"
          >
            Ano Fundação
          </div>
          <div class="box40 sb-1 sb-right results text-center">
            {{ resultados[`bk_304_006_dt_fundacao_ano_${index + 1}`] }}
          </div>
        </div>
        <div>
          <div
            class="box40 sb-1 titles text-center"
            :class="{ escuro: index % 2 !== 0 }"
            :id="'B3Ranking-7' + index"
          >
            Tempo Fundação
          </div>
          <div class="box40 sb-1 results flex-row text-center" style="gap: 3px">
            <span>
              {{ resultados[`bk_304_006_tmp_fundacao_ano_${index + 1}`] }}
            </span>
            <span
              v-if="resultados[`bk_304_006_tmp_fundacao_ano_${index + 1}`] > 1"
            >
              anos
            </span>
            <span
              v-if="resultados[`bk_304_006_tmp_fundacao_ano_${index + 1}`] == 1"
            >
              ano
            </span>
          </div>
        </div>
      </div>
      <div class="box3">
        <div>
          <div
            class="box40 titles sb-1 sb-right text-center"
            :class="{ escuro: index % 2 !== 0 }"
            :id="'B3Ranking-8' + index"
          >
            Qtd Sócios
          </div>
          <div class="box40 sb-1 sb-right results text-center">
            {{ resultados[`bk_304_006_num_socios_${index + 1}`] }}
          </div>
        </div>
        <div>
          <div
            class="box40 titles sb-2 w160"
            :class="{ escuro: index % 2 !== 0 }"
            :id="'B3Ranking-9' + index"
          >
            Capital Social
          </div>
          <div class="box40 sb-2 results">
            {{
              formatarNumeroComPonto(
                resultados[`bk_304_006_vlr_capital_social_${index + 1}`]
              )
            }}
          </div>
        </div>
      </div>
    </div>
    <div v-if="abreBloco">
      <div v-for="(tt, i) in tooltipsBloco3" :key="i">
        <b-tooltip
          v-if="index == 0 && exibirTootipsConsultas"
          :target="tt.target + index"
          triggers="hover"
          placement="bottom"
          variant="dark"
          boundary="window"
          customClass="tooltip-custom"
        >
          <div v-html="tt.texto"></div>
        </b-tooltip>
      </div>
    </div>
  </div>
</template>

<script>
import { userPreferenceStore } from "@/stores/userPreference";
import { dadosConsultaAddStore } from "@/stores/dadosConsultaAdd";
import { mapWritableState, mapActions } from "pinia";
import listaTooltips from "@/helpers/lista_tooltips_superbox.json";

export default {
  name: "B3Ranking",

  props: {
    item: Object,
    index: Number,
    abreBloco: Boolean,
    resultados: Object,
    exibirTootipsConsultas: Boolean,
    gerandoPdf: Boolean,
  },

  data() {
    return {
      statusRegiao: "Nulo",
      statusOptions: [
        "Ativa",
        "Inativa",
        "Suspensa",
        "Inapta",
        "Baixada",
        "Nula",
      ],
    };
  },

  methods: {
    selecionaConsultaAdicional(documento) {
      this.abrirSelecaoConsulta(documento, "PJ");
    },

    fechaConsultaAdicional() {
      this.fecharSelecaoConsulta();
    },

    naturezaJuridicaTratada(index) {
      const valor =
        this.resultados[`bk_304_006_des_tipo_natureza_juridica_${index + 1}`];
      if (valor) {
        if (valor.includes("(")) {
          const match = valor.match(/\((.*?)\)/);
          if (match) {
            return match[1];
          }
        }
      }

      return valor;
    },

    compararValores(str1, str2) {
      if (str1 != "-" && str1 != null && str2 != "-" && str2 != null) {
        const removerAcentos = (str) => {
          return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
        };

        const prepararString = (str) => {
          return str.trim().toLowerCase();
        };

        return (
          prepararString(removerAcentos(str1)) ===
          prepararString(removerAcentos(str2))
        );
      } else {
        return false;
      }
    },

    formatarCNPJ(cnpj) {
      if (cnpj && cnpj != "-") {
        cnpj = cnpj.replace(/\D/g, "");

        cnpj = cnpj.replace(/^(\d{2})(\d)/, "$1.$2");
        cnpj = cnpj.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3");
        cnpj = cnpj.replace(/\.(\d{3})(\d)/, ".$1/$2");
        cnpj = cnpj.replace(/(\d{4})(\d)/, "$1-$2");
      }
      return cnpj;
    },

    formatarNumeroComPonto(numero) {
      if (numero) {
        if (numero != "-") {
          const numeroString = numero.toString().replace(".", ",");
          const partes = numeroString.split(",");
          partes[0] = partes[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
          return partes.join(",");
        } else {
          return numero;
        }
      }
    },

    ...mapActions(dadosConsultaAddStore, [
      "abrirSelecaoConsulta",
      "fecharSelecaoConsulta",
    ]),
  },

  computed: {
    tooltipsBloco3() {
      return listaTooltips.bloco03_regiao;
    },

    telaHistorico() {
      return this.$route.path.includes("/historico");
    },

    ...mapWritableState(userPreferenceStore, {
      usuarioLogado: "usuarioLogado",
      ocultarDados: "ocultarDados",
    }),
  },
  created() {},
};
</script>

<style lang="scss" scoped>
.topEmpresasBox {
  width: 950px;
  height: 190px;
  display: flex;
  font-size: 12px;
  margin: 10px 0 5px 5px;
  border-bottom: dashed 1px #0070c0;
  cursor: default;

  &.last {
    border: none;
  }
}

.boxId {
  border: solid 1px #0070c0;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 14px;

  @include flex-center(column);

  &.escuro {
    background-color: #b4c6e7;
  }
}

.box01 {
  height: 180px;
  display: flex;
  flex-direction: column;
}

.box3 {
  display: flex;
}

.box40 {
  height: 40px;
  padding: 1px 10px;
  margin: 0 0 5px 0px;
  cursor: default;
  line-height: 13px;

  @include flex-center(column);

  &.titles {
    background-color: #d9e1f2;
    border-radius: 5px;
    height: 40px;

    &.escuro {
      background-color: #b4c6e7;
    }
  }

  &.results {
    border: solid 1px #0070c0;
    border-radius: 5px;
  }
}

.box80 {
  height: 85px;
  padding: 1px 10px;
  margin: 0 0 5px 0px;
  text-align: center;
  cursor: default;
  line-height: 14px;

  @include flex-center(column);

  &.titles {
    background-color: #d9e1f2;
    border-radius: 5px;
    &.escuro {
      background-color: #b4c6e7;
    }
  }
  &.results {
    border: solid 1px #0070c0;
    border-radius: 5px;
  }
}

.flex {
  display: flex;
}

.tabTextBox {
  border: solid 1px #0070c0;
  border-radius: 5px;
  padding: 1px 10px;
  margin: 0px 0 5px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex: 1;
  line-height: 18px;
}

.tabText {
  display: flex;
  margin: 0 0 0 0px !important;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;

  &.selecionado {
    color: #68a490;
    font-weight: 600;
  }
  &.nao-selecionado {
    color: #a6a6a6;
  }
}

.text-send {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 5px;
  flex-direction: row;
  padding: 5px;
}

.icon-send {
  color: #0070c0;
  width: 24px;
  font-size: 20px;
  cursor: pointer;
  transition: font-size 0.3s;

  &:hover {
    font-size: 22px;
  }
}
</style>
<style src="@/styles/components/superbox_customizado.scss" scoped lang="scss" />
