<template>
  <div class="container-map">
    <div class="row-map">
      <div class="options-box sb-2 sb-right">
        <span class="titleBlue">
          Pontos de Visualização <br />
          (Raio de 1 km)
        </span>
        <span class="item">
          <span class="texto" :id="'B3Mapa-eds'">
            <span class="mark-pin laranja"></span> EDS's
          </span>
          <span class="check-box" :id="'B3Mapa-check-eds'">
            <md-switch
              v-model="checkEDS"
              :disabled="poligonoEDS.length === 0"
              class="sw"
              @change="configuraPoligonoEDS()"
            >
            </md-switch>
          </span>
        </span>
        <span class="item">
          <span class="texto" :id="'B3Mapa-efs'">
            <img class="icon-pin" :src="purple" alt="" /> EFS's
          </span>
          <span class="check-box" :id="'B3Mapa-check-efs'">
            <md-switch
              v-model="checkEFS"
              :disabled="marcasEFS.length === 0"
              class="sw"
              @change="configuraMarcasEFS()"
            >
            </md-switch>
          </span>
        </span>
        <span class="item">
          <span class="texto" :id="'B3Mapa-eap'">
            <img class="icon-pin" :src="blue" alt="" />
            EAP's
          </span>
          <span class="check-box" :id="'B3Mapa-check-eap'">
            <md-switch
              v-model="checkEAP"
              :disabled="marcasEAP.length === 0"
              class="sw"
              @change="configuraMarcasEAP()"
            >
            </md-switch>
          </span>
        </span>
        <span class="item">
          <span class="texto" :id="'B3Mapa-eds'">
            <span class="mark-pin roxo"></span> Inundações
          </span>
          <span class="check-box" :id="'B3Mapa-check-eds'">
            <md-switch
              v-model="checkAlagamento"
              :disabled="poligonoAlagamentos.length === 0"
              class="sw"
              @change="configuraPoligonoAlagamentos()"
            >
            </md-switch>
          </span>
        </span>
        <span class="titleBlue -polig"> Polígonos de municípios/áreas urbanas </span>
        <span class="item sb-right">
          <span class="texto" :id="'B3Mapa-divisas'"> Divisas de municípios </span>
          <span class="check-box">
            <md-switch
              v-model="checkPoligonoDivisas"
              class="sw"
              @change="configuraPoligonoDivisas()"
            >
            </md-switch>
          </span>
        </span>
        <span class="item">
          <span class="texto" :id="'B3Mapa-areas'"> Classificação de áreas </span>
          <span class="check-box">
            <md-switch
              v-model="checkPoligonoAreas"
              class="sw"
              @change="configuraPoligonoClassif()"
            >
            </md-switch>
          </span>
        </span>
      </div>
      <div class="map-box" id="map-box-geo" v-if="this.centro.coordenada != undefined">
        <Maps
          :center="centro"
          :marcas="marcas"
          :poligonos="poligonos"
          :dimensoes="{ width: '785px', height: '443px' }"
          :zoom="zoomMapa"
          ref="mapComponent"
          :gmapCircle="true"
        />
      </div>
      <div
        class="map-img-box"
        v-if="this.centro.coordenada == undefined"
        style="width: 785px; height: 400px; overflow: hidden"
      >
        <img
          src="/img/superbox/mapa-brasil.png"
          alt=""
          class="map-img"
          style="width: 775px; height: 430px"
        />
      </div>
    </div>
    <div v-if="abreBloco">
      <div v-for="(tt, i) in tooltipsBloco3Mapa" :key="i">
        <b-tooltip
          v-if="exibirTootipsConsultas"
          :target="tt.target"
          triggers="hover"
          placement="bottom"
          variant="dark"
          boundary="window"
          customClass="tooltip-custom"
        >
          <div v-html="tt.texto"></div>
        </b-tooltip>
      </div>
    </div>
  </div>
</template>

<script>
import { mapasService } from "@/services";
import { chatOutboxStore } from "@/stores/chat-Outbox.js";
import { mapActions, mapWritableState } from "pinia";

import Maps from "@/components/Maps.vue";
import listaTooltips from "@/helpers/lista_tooltips_superbox.json";

export default {
  name: "B3Mapa",
  components: {
    Maps,
  },

  props: {
    index: Number,
    abreBloco: Boolean,
    codigo_agrupador: String,
    resultados: Object,
    exibirTootipsConsultas: Boolean,
  },

  data() {
    return {
      red: "/img/superbox/pin-vermelho.png",
      blue: "/img/superbox/pin-azul.png",
      purple: "/img/superbox/pin-roxo.png",

      zoomMapa: 14,
      marcas: [],
      poligonos: [],
      checkEDS: true,
      checkEFS: true,
      checkEAP: true,
      checkAlagamento: true,
      checkPoligonoDivisas: false,
      checkPoligonoAreas: false,
      centro: {},
      marcasEFS: [],
      marcasEAP: [],
      poligonoEDS: [],
      poligonoAlagamentos: [],
      poligonoDivisas: [],
      poligonoAreas: [],
    };
  },

  watch: {
    poligonoAlagamentos: {
      handler(newValue) {
        if (newValue.length === 0 && this.checkAlagamento === true) {
          this.checkAlagamento = false;
        }
      },
      deep: true,
    },

    poligonoEDS: {
      handler(newValue) {
        if (newValue.length === 0 && this.checkEDS === true) {
          this.checkEDS = false;
        }
      },
      deep: true,
    },

    marcasEFS: {
      handler(newValue) {
        if (newValue.length === 0 && this.checkEFS === true) {
          this.checkEFS = false;
        }
      },
      deep: true,
    },

    marcasEAP: {
      handler(newValue) {
        if (newValue.length === 0 && this.checkEAP === true) {
          this.checkEAP = false;
        }
      },
      deep: true,
    },
  },

  computed: {
    tooltipsBloco3Mapa() {
      return listaTooltips.bloco03_mapa;
    },
  },

  methods: {
    async configuraMapa() {
      await this.adicionaMarcaCentro();

      if (this.checkAlagamento) {
        await this.configuraPoligonoAlagamentos();
      } else {
        await this.removePoligonoAlagamentos();
      }

      if (this.checkEDS) {
        await this.configuraPoligonoEDS();
      } else {
        await this.removePoligonoEDS();
      }

      if (this.checkEFS) {
        await this.configuraMarcasEFS();
      } else {
        await this.removeMarcasEFS();
      }

      if (this.checkEAP) {
        await this.configuraMarcasEAP();
      } else {
        await this.removeMarcasEAP();
      }
    },

    async adicionaMarcaCentro() {
      const centroIndex = this.marcas.findIndex((marca) => marca.tipo === "centro");

      if (centroIndex === -1) {
        this.marcas.push({
          coordenada: this.centro.coordenada,
          iconColor: this.red,
          descricao: "CEP: " + this.formatarCEP(this.resultados.bk_300_003_cep_1),
          distancia: 0,
          tipo: "centro",
        });
      }
    },

    formatarCEP(numero) {
      let cepLimpo = numero.replace(/\D/g, "");

      if (cepLimpo.length !== 8) {
        return numero;
      }

      return cepLimpo.replace(/^(\d{5})(\d{3})$/, "$1-$2");
    },

    async configuraPoligonoClassif() {
      if (this.checkPoligonoAreas) {
        this.poligonoAreas = await mapasService.recuperaCoordenadasClassificacao(
          this.codigo_agrupador
        );

        this.poligonoAreas.forEach((poligono, index) => {
          if (poligono.descricao == "poligono_area_central") {
            poligono.options = {
              strokeColor: "#72E400", //verde 'arborização'
              strokeOpacity: 0.8,
              strokeWeight: 2,
              fillColor: "#72E400",
              fillOpacity: 0.1,
              zIndex: 5,
            };
          }

          if (poligono.descricao == "poligono_area_periferica") {
            poligono.options = {
              strokeColor: "#5F78E0", //azul 'esgoto céu aberto'
              strokeOpacity: 0.8,
              strokeWeight: 2,
              fillColor: "#5F78E0",
              fillOpacity: 0.1,
              zIndex: 3,
            };
          }
          if (poligono.descricao == "poligono_faixa_litoranea") {
            poligono.options = {
              strokeColor: "#FDBF2D", //amarelo
              strokeOpacity: 0.8,
              strokeWeight: 2,
              fillColor: "#FDBF2D",
              fillOpacity: 0.1,
              zIndex: 3,
            };
          }
          if (poligono.descricao == "poligono_area_urbana") {
            poligono.options = {
              strokeColor: "#4BCCC9", //azul 'alvenaria exposta'
              strokeOpacity: 0.8,
              strokeWeight: 2,
              fillColor: "#4BCCC9",
              fillOpacity: 0.1,
              zIndex: 4,
            };
          }

          if (poligono.descricao == "poligono_urbano") {
            poligono.options = {
              strokeColor: "#374581", //azul 'lixo acumulado'
              strokeOpacity: 0.8,
              strokeWeight: 2,
              fillColor: "transparent",
              fillOpacity: 0,
              zIndex: 2,
            };
          }
          if (poligono.descricao == "poligono_regiao_metropolitana") {
            poligono.options = {
              strokeColor: "#5F78E0", //azul 'esgoto céu aberto'
              strokeOpacity: 0.8,
              strokeWeight: 2,
              fillColor: "transparent",
              fillOpacity: 0,
              zIndex: 1,
            };
          }

          poligono.tipo = "ClassificacaoAreas";
        });

        this.poligonos.push(...this.poligonoAreas);

        this.zoomMapa = 12;
      } else {
        this.removePoligonoClassif();
      }
    },

    async removePoligonoClassif() {
      this.poligonos = this.poligonos.filter((poligono) => {
        const index = this.poligonoAreas.findIndex(
          (polig) => JSON.stringify(poligono) === JSON.stringify(polig)
        );
        return index === -1;
      });

      // this.centralizarMapa();
    },

    async configuraPoligonoDivisas() {
      if (this.checkPoligonoDivisas) {
        this.poligonoDivisas = await mapasService.recuperaCoordenadasDivisas(
          this.codigo_agrupador
        );

        this.poligonoDivisas.forEach((poligono, index) => {
          poligono.options = {
            strokeColor: "#0070C0",
            strokeOpacity: 1,
            strokeWeight: 4,
            fillColor: "transparent",
            fillOpacity: 0,
            zIndex: 0,
          };

          poligono.tipo = "DivisasMunicipio";
        });

        this.poligonos.push(...this.poligonoDivisas);
        this.zoomMapa = 12;
      } else {
        this.removePoligonoDivisas();
      }
    },

    removePoligonoDivisas() {
      this.poligonos = this.poligonos.filter((poligono) => {
        const index = this.poligonoDivisas.findIndex(
          (polig) => JSON.stringify(poligono) === JSON.stringify(polig)
        );
        return index === -1;
      });

      // this.centralizarMapa();
    },

    async configuraPoligonoEDS() {
      if (this.checkEDS) {
        this.poligonoEDS = await mapasService.recuperaCoordenadasEds(
          this.codigo_agrupador
        );

        let menorDistancia = Infinity;
        let indexPoligonoMenorDistancia = -1;

        this.poligonoEDS.forEach((poligono, index) => {
          if (poligono.distancia < menorDistancia) {
            menorDistancia = poligono.distancia;
            indexPoligonoMenorDistancia = index;
          }
        });

        if (indexPoligonoMenorDistancia !== -1) {
          this.poligonoEDS.forEach((poligono, index) => {
            if (index === indexPoligonoMenorDistancia) {
              poligono.options = {
                strokeColor: "#FF0000",
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: "#FF0000",
                fillOpacity: 0.35,
                zIndex: 11,
              };
            } else {
              poligono.options = {
                strokeColor: "#E88706",
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: "#E88706",
                fillOpacity: 0.35,
                zIndex: 11,
              };
            }

            poligono.tipo = "EDS";
          });
        }

        this.poligonos.push(...this.poligonoEDS);
      } else {
        this.removePoligonoEDS();
      }
    },

    async removePoligonoEDS() {
      this.poligonos = this.poligonos.filter((poligono) => {
        const index = this.poligonoEDS.findIndex(
          (poligEDS) => JSON.stringify(poligono) === JSON.stringify(poligEDS)
        );
        return index === -1;
      });
    },

    async configuraPoligonoAlagamentos() {
      if (this.checkAlagamento) {
        this.poligonoAlagamentos = await mapasService.recuperaCoordenadasInundacoes(
          this.codigo_agrupador
        );

        let menorDistancia = Infinity;
        let indexPoligonoMenorDistancia = -1;

        this.poligonoAlagamentos.forEach((poligono, index) => {
          if (poligono.distancia < menorDistancia) {
            menorDistancia = poligono.distancia;
            indexPoligonoMenorDistancia = index;
          }
        });

        if (indexPoligonoMenorDistancia !== -1) {
          this.poligonoAlagamentos.forEach((poligono, index) => {
            if (index === indexPoligonoMenorDistancia) {
              poligono.options = {
                strokeColor: "#000bff",
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: "#000bff",
                fillOpacity: 0.35,
                zIndex: 11,
              };
            } else {
              poligono.options = {
                strokeColor: "#0093ff",
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: "#0093ff",
                fillOpacity: 0.35,
                zIndex: 11,
              };
            }

            poligono.tipo = "Inundações";
          });
        }

        this.poligonos.push(...this.poligonoAlagamentos);
      } else {
        this.removePoligonoAlagamentos();
      }
    },

    async removePoligonoAlagamentos() {
      this.poligonos = this.poligonos.filter((poligono) => {
        const index = this.poligonoAlagamentos.findIndex(
          (poligAlag) => JSON.stringify(poligono) === JSON.stringify(poligAlag)
        );
        return index === -1;
      });
    },

    async configuraMarcasEFS() {
      if (this.checkEFS) {
        this.marcasEFS = await mapasService.recuperaCoordenadasEfs(this.codigo_agrupador);

        this.marcasEFS.forEach((marca) => {
          marca.iconColor = this.purple;
          marca.tipo = "EFS";
        });

        this.marcas.push(...this.marcasEFS);
      } else {
        await this.removeMarcasEFS();
      }
    },

    async removeMarcasEFS() {
      this.marcas = this.marcas.filter((marca) => {
        const index = this.marcasEFS.findIndex(
          (marcaEFS) => JSON.stringify(marca) === JSON.stringify(marcaEFS)
        );
        return index === -1;
      });
    },

    async configuraMarcasEAP() {
      if (this.checkEAP) {
        this.marcasEAP = await mapasService.recuperaCoordenadasEap(this.codigo_agrupador);

        this.marcasEAP.forEach((marca) => {
          marca.iconColor = this.blue;
          marca.tipo = "EAP";
        });

        this.marcas.push(...this.marcasEAP);
      } else {
        await this.removeMarcasEAP();
      }
    },

    async removeMarcasEAP() {
      this.marcas = this.marcas.filter((marca) => {
        const index = this.marcasEAP.findIndex(
          (marcaEAP) => JSON.stringify(marca) === JSON.stringify(marcaEAP)
        );
        return index === -1;
      });
    },

    async buscarCentro() {
      let tentativas = 0;
      const maximoTentativas = 10;
      const esperar = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

      while (this.centro.coordenada === undefined && tentativas < maximoTentativas) {
        try {
          this.centro = await mapasService.recuperaCoordenadasPrincipal(
            this.codigo_agrupador
          );
          if (this.centro.coordenada == undefined) {
            this.checkEDS = false;
            this.checkEFS = false;
            this.checkEAP = false;
            this.checkAlagamento = false;
          } else {
            this.checkEDS = true;
            this.checkEFS = true;
            this.checkEAP = true;
            this.checkAlagamento = true;
            break;
          }
        } catch (erro) {}

        await esperar(1500);

        tentativas++;
      }

      this.enviaResultadoCentro(this.centro);
    },

    enviaResultadoCentro(centro) {
      const obj = centro.coordenada;
      this.recebeResultadoSuperbox(obj);
    },

    ...mapActions(chatOutboxStore, ["recebeResultadoSuperbox"]),
  },

  async mounted() {
    if (this.codigo_agrupador !== "") {
      await this.buscarCentro();
      this.configuraMapa();
    }
  },
};
</script>

<style lang="scss" scoped>
.container-map {
  display: flex;
  flex-direction: column;
  margin: 40px 0;
}
.row-map {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  &.horiz {
    margin-left: 155px;
  }
}

.map-box {
  display: flex;
}

.titleBlue {
  background-color: #0070c0;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-size: 12px;
  font-weight: 400;
  text-align: center;
  border-radius: 5px;
  padding: 5px;
  height: 80px;
  cursor: default;
  line-height: 14px;

  &.horiz {
    height: 40px;
    margin-top: 5px;
  }
  &.-polig {
    margin-top: 15px;
  }
}

.item {
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 40px;
  margin-top: 5px;
  font-size: 12px;
  font-weight: 400;
  border: solid 1px #0070c0;
  color: #0070c0;
  cursor: default;
  line-height: 14px;
  justify-content: space-between;
  padding: 0 10px;
}
.texto {
  //width: 50%;
  display: flex;
  align-items: center;
  gap: 5px;
}
.icon-pin {
  width: 20px;
}
.mark-pin {
  width: 15px;
  border-radius: 50%;
  height: 15px;

  &.laranja {
    background-color: #e8870650;
    border: 2px solid #e88706;
  }
  &.roxo {
    background-color: #0093ff50;
    border: 2px solid #0093ff;
  }
}

.sw {
  width: 50%;
  text-align: right;
  margin: 0 0 0 10px;
}
</style>
<style src="@/styles/components/superbox_customizado.scss" scoped lang="scss" />
